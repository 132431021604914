// noinspection JSUnusedGlobalSymbols,JSUnresolvedVariable

import { Typography } from '@material-ui/core'
import React from 'react'
import { Layout } from '../components/_layout'


export default function Error404() {

  return (
      <Layout
          withNav
          seoTitle="Error"
          seoDescription="Página no encontrada"
      >
        <Typography>Error: No se encontró la página</Typography>
      </Layout>
  )
}
